import React,{useState} from 'react'
import { Card, Image, Stack, CardBody, Heading, Text, CardFooter, Button, Box ,FormControl,FormLabel,Input} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch,useSelector } from 'react-redux'
import { loginAsync } from '../../../redux/reducers/authReducer'
import { Navigate } from 'react-router-dom'

const Login = () => {
  const dispatch = useDispatch();
    const { loading, error } = useSelector(state => state.auth);
    const [credentials, setCredentials] = useState({ customerId: '', password: '' });
    const navigate=useNavigate();
    const handleLogin = async(e) => {
      e.preventDefault();
      try {
          const resultAction = await dispatch(loginAsync(credentials));
          const {payload}=resultAction;
          if (loginAsync.fulfilled.match(resultAction)) {
            // Here, payload should contain the user data including token
      console.log('Logged in successfully:', payload);

      // You can store the token in localStorage or sessionStorage for persistence
      localStorage.setItem('customerId', payload.customerId);
              navigate('/dashboard');
              window.location.reload('/dashboard') // Navigate to homepage on successful login
          }
      } catch (error) {
          console.error('Failed to login:', error);
      }
       
    };
  return (
    <Box display='flex' alignItems="center" justifyContent="center" height="100vh" bg='#FFEFD5' >
      
      <Card
        direction={{ base: 'column', md: 'row' }}
        overflow='hidden'
        variant='outline'
        alignItems="center" justifyContent="center"
        
        bg='#FEA116'
       p={3}
        
        
      >
        <Image
          objectFit='cover'
          maxW={{ base: '100%', sm: '200px' }}
          display={{lg:'block',md:'none',sm:'none'}}
          height='50vh'
          borderRadius='12px'
          src='https://images.unsplash.com/photo-1667489022797-ab608913feeb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHw%3D&auto=format&fit=crop&w=800&q=60'
          alt='Caffe Latte'
        />

        <Stack>
          <CardBody>
            <FormControl isRequired>
            <Text fontSize='3xl' as='b'  >Login</Text>

            <Box   display="flex" alignItems="center"  boxShadow='md'
            borderRadius="5px"
            
            p={4}>
              <FormLabel color='black'  >CustomerId</FormLabel>
              <Input w='66%' bg='#FAFAFA ' placeholder="Enter your CustomerId" value={credentials.customerId} onChange={(e) => setCredentials({ ...credentials, customerId: e.target.value })}   />
            </Box>
            <Box mb={4}  display="flex" alignItems="center"  boxShadow='md'
            borderRadius="5px"
            
            p={4}>
              <FormLabel color='black'  >Password</FormLabel>
              <Input w='70%' type='password' bg='#FAFAFA ' placeholder="Enter your password" value={credentials.password} onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}  />
            </Box>
            <Button  variant='solid' color='#FEA116' bg='#0F172B' _hover={{color:'white'}} onClick={handleLogin}>
              Login
            </Button>
            </FormControl>
            
          </CardBody>
         

          <CardFooter alignItems="center" justifyContent="center">
            {/* <Link to='/signup'><b>If not Login?Signup</b></Link> */}
          </CardFooter>
        </Stack>
      </Card>
      </Box>
    
  )
}

export default Login
