import React,{useState,useEffect,useRef} from 'react';
import toast from 'react-hot-toast';
import axios from 'axios';
import {
    Box,Input,
    Button,
    Flex,
    Form, Table,
    TableCaption,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    } from '@chakra-ui/react';


const PurchaseReport=()=>{
    const [data,setData]=useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    
    const customerId=localStorage.getItem('customerId')
    const fetchData=async()=>{
        try {
            const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getPurchaseBill/${customerId}`)
            toast.success(response.data.message);
            console.log(response)
            setData(response.data.purchase)
            setFilteredData(response.data.purchase)
        } catch (error) {
            toast.error(error.message);
        }
    }
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value);
      filterData(event.target.value, startDate, endDate);
    };
  
    const handleStartDateChange = (event) => {
      setStartDate(event.target.value);
      filterData(searchTerm, event.target.value, endDate);
    };
  
    const handleEndDateChange = (event) => {
      setEndDate(event.target.value);
      filterData(searchTerm, startDate, event.target.value);
    };
    const filterData = (term, start, end) => {
      let filtered = data.filter(order => {
        const orderDate = new Date(order.date).getTime();
        const startFilter = start ? new Date(start).getTime() : null;
        const endFilter = end ? new Date(end).getTime() : null;
  
        if (!term && !startFilter && !endFilter) {
          return true; // No filters applied, return all data
        }
  
        const matchesTerm = term && (
          order.name.toLowerCase().includes(term.toLowerCase()) 
          // Include roomNo in search
        );
  
        const withinDateRange = (!startFilter || orderDate >= startFilter) &&
          (!endFilter || orderDate <= endFilter);
  
        return matchesTerm && withinDateRange;
      });
  
      setFilteredData(filtered);
    };
  
    useEffect(()=>{
        fetchData()
    },[customerId])
    return (
        <Box>
        <Box>
           <Input
          w='40%'
          type="text"
          placeholder="Search by Registration or Room No"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Input
          w='20%'
          type="date"
          placeholder="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          mr={2}
        />
        <Input
          w='20%'
          type="date"
          placeholder="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          mr={2}
        />
      </Box>
               <TableContainer width='100vw - 250px'>
        <Table size='sm' variant='striped' colorScheme='green'>
          <TableCaption>Food Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Date</Th>

              <Th>Adress</Th>
              <Th>Balance</Th>
              <Th>Bill No</Th>
              <Th>Challan No</Th>
              <Th>Invoice No</Th>
              <Th>Items</Th>
              <Th>Store</Th>
              <Th>CGST</Th>
              <Th>SGST</Th>
              <Th>Discount</Th>
              <Th>Discount(%)</Th>
              <Th>Total Tax</Th>
              <Th>Gross Amount</Th>
              <Th>Net Amount</Th>
             
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (
              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.date}</Td>
                <Td>{item.address}</Td>
                <Td>{item.balance}</Td>
                <Td>{item.billNo}</Td>
                <Td>{item.challanNo}</Td>
                <Td>{item.invoiceNo}</Td>
                <Td><Table size='sm' variant='striped' colorScheme='blue'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                    <Tr>
                    <Th>Name</Th>
                    <Th>Quantity</Th>
                    <Th>Unit</Th>
                    <Th>Mrp</Th>
                    <Th>Purchase Rate</Th>
                    <Th>Cgst</Th>
                    <Th>Sgst</Th>
                    <Th>Amount</Th>
                    </Tr>
                    </Thead>
                    <Tbody>
                    {item.item.map((item, index) => (
                    <Tr key={index}>
                    <Td>{item.itemName}</Td>
                    <Td>{item.quantity}</Td>
                    <Td>{item.unit}</Td>
                    <Td>{item.mrp}</Td>
                    <Td>{item.purchaseRate}</Td>
                    <Td>{item.cgst}</Td>
                    <Td>{item.sgst}</Td>
                    <Td>{item.amount}</Td>
                    </Tr>))}
                    </Tbody>
                </Table></Td>
                <Td>{item.store}</Td>
                <Td>{item.cgstTotal}</Td>
                <Td>{item.sgstTotal}</Td>
                <Td>{item.cashDiscountAmount}</Td>
                <Td>{item.cashDiscountPercentage}</Td>
                <Td>{item.totalTax}</Td>
                <Td>{item.grossAmount}</Td>
                <Td>{item.netAmount}</Td>

               
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
        </Box>
    )
}
export default PurchaseReport
