import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  FormControl,
  FormLabel,useDisclosure,
  AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { numberToWords } from '../../utils/numberToWords';
import CashUpdateModal from './CashEditModal';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import * as XLSX from 'xlsx'
const CashData = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [printData, setPrintData] = useState([]);
  const [selectId,setSelectId]=useState(null)
  const [toggle1,setToggle1]=useState(false)
  const [cashData,setCashData]=useState([])
  const [isOpen1,setIsOpen]=useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null);
  const cancelRef = useRef();
  const customerId = localStorage.getItem('customerId');
  
const navigate=useNavigate()
  const fetchData = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getCashRecipt/${customerId}`);
    setData(response.data);
    setFilteredData(response.data);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchData3 = async () => {
    const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`);
    setPrintData(response.data);
    const rooms = response.data;
    const updatedRooms = await Promise.all(
      rooms.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
    setPrintData(updatedRooms);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value, startDate, endDate);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    }
    filterData(searchTerm, name === 'startDate' ? value : startDate, name === 'endDate' ? value : endDate);
  };
  //import XLSX from 'xlsx';

 // import XLSX from 'xlsx';

  const exportToExcel = () => {
    // Filter out _id and createdAt fields from the data
    const cleanedData = filteredData.map(({ _id, createdAt, ...rest }) => rest);
  
    // Create a worksheet from the cleaned data
    const worksheet = XLSX.utils.json_to_sheet(cleanedData);
  
    // Capitalize the first letter of each header
    const headers = Object.keys(cleanedData[0] || {});
    headers.forEach((header, index) => {
      const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
      worksheet[cellAddress].v = header.charAt(0).toUpperCase() + header.slice(1);
    });
  
    // Calculate totals for cash and online columns
    let totalCash = 0;
    let totalOnline = 0;
    
    cleanedData.forEach((row) => {
      totalCash += parseInt(row.cash) || 0;
      totalOnline += parseInt(row.online) || 0;
    });
  
    // Add the totals row
    const totalRow = {
      cash: totalCash,
      online: totalOnline,
      // Add any other columns you want to include in the totals row
    };
    
    // Append the total row to the worksheet
    XLSX.utils.sheet_add_json(worksheet, [totalRow], { skipHeader: true, origin: -1 });
  
    // Add borders to all cells in the worksheet
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = { c: C, r: R };
        const cellRef = XLSX.utils.encode_cell(cellAddress);
  
        if (!worksheet[cellRef]) continue;
        if (!worksheet[cellRef].s) worksheet[cellRef].s = {};
        
        worksheet[cellRef].s.border = {
          top: { style: "thin" },
          bottom: { style: "thin" },
          left: { style: "thin" },
          right: { style: "thin" },
        };
      }
    }
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Cash Data');
  
    // Write the workbook to an Excel file
    XLSX.writeFile(workbook, 'CashData.xlsx');
  };

  
  const filterData = (term, startDate, endDate) => {
    let filtered = data;

    // Filter by search term (phone number or registration)
    if (term) {
      filtered = filtered.filter(item =>
        item.phoneNo.includes(term) || item.registration.includes(term) || item.name.toLowerCase().includes(term.toLowerCase())
      );
    }

    // Filter by date range
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      filtered = filtered.filter(item => {
        const itemDate = new Date(item.date);
        return itemDate >= start && itemDate <= end;
      });
    }

    setFilteredData(filtered);
  };
  const edit = async (id) => {
    try {
      const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getCashReciptById/${customerId}/${id}`);
      setCashData(response.data);
      setSelectId(id);
      onOpen(); // Open the modal after fetching data
    } catch (error) {
      toast.error('Failed to fetch Cash data');
    }
  };
const handleDelete = async () => {
  try {
    const response = await axios.delete(`https://api.hotel.tdrsoftware.in/api/deleteCashRecipt/${customerId}/${deleteItemId}`);
    
      toast.success('Data deleted successfully');
      onClose1()
      fetchData();
      
  } catch (error) {
    console.error('Error deleting data:', error);
    ;
    toast.error('Failed to delete data');
  }
}
  const handlePrint = (item) => {
    const content = `
      <html>
        <head>
          <style>
            body { font-family: Arial, sans-serif; color: purple; }
            .grid-container { display: grid; grid-template-columns: repeat(3, 1fr); gap: 8px; margin-top: 10px; }
            .grid-item { padding: 8px; border: 1px solid #ddd; }
            .container { display: flex; justify-content: space-between; align-items: center; gap: 8px; margin-top: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #dddddd; text-align: left; padding: 8px; }
            th { background-color: #f2f2f2; }
            .container2 { display: flex; flex-direction: row; padding: 8px; }
            .column { display: flex; flex-direction: column; border: 1px solid black; }
            .column-right { display: flex; flex-direction: column; border-top: 1px solid black; border-right: 1px solid black; border-bottom: 1px solid black; border-left: 0; }
            .header { border-bottom: 1px solid black; background-color: #e2e8f0; padding: 4px; }
            .text { padding: 2px; }
            .border-top { border-top: 1px solid black; }
          </style>
        </head>
        <body>
          <h2 style="text-align: center">Cash Receipt</h2>
          <div style="display: flex; justify-content: center; align-items: center; gap: 18px; color: purple">
            ${printData.map((item) => `
              <img src="${item.imageUrl}" style="width: 200px; height: 200px; border-radius: 12px; margin-right: 10px; background: #FAFAFA" />
              <div>
                <h2>${item.name}</h2>
                <h5>${item.adress}</h5>
                <h5>Phone: ${item.phoneNo}</h5>
                <h5>Website: ${item.website}</h5>
                <h5>Email: ${item.email}</h5>
                <h5>GST No: ${item.gstNo}</h5>
              </div>
            `).join('')}
          </div>
          <div class="grid-container">
            <p>Guest Name: ${item.name}</p>
            <p>Date: ${item.date}</p>
            <p>Phone No: ${item.phoneNo}</p>
            <p>Room No: ${item.roomNo}</p>
            <p>Check-In: ${item.checkInDate}</p>
            <p>Check-Out: ${item.checkOutDate}</p>
            <p>Payment Type: ${item.paymentType}</p>
            <p>Remarks: ${item.remarks}</p>
            <p>Cash Paid: ${item.cash}</p>
            <p>Discount: ${item.discount}</p>
            <p>Mode: ${item.mode}</p>
            <p>Transaction ID: ${item.transaction}</p>
            <p>Online Paid: ${item.online}</p>
          </div>
          <div style="display: flex; justify-content: space-between; align-items: center;">
            ${printData.map((print) => `
              <div>
                <h3>Bank Details</h3>
                <h4>A/C No: ${print.acNo}</h4>
                <h5>IFSC: ${print.ifsc}</h5>
                <h5>Branch: ${print.branch}</h5>
              </div>
              <div>
                <h3>Summary</h3>
                <h4>Cash: ${item.cash}</h4>
                <h4>Online Paid: ${item.online}</h4>
                <h4>Total Amount: ${parseInt(item.cash ? item.cash : 0) + parseInt(item.online ? item.online : 0)}</h4>
              </div>
            `).join('')}
          </div>
          <h3>Total: ${numberToWords(parseInt(item.cash) + parseInt(item.online))} (${parseInt(item.cash) + parseInt(item.online)})</h3>
          <div class='container'>
            <div>
              ${printData.map((item) => `
                <div>
                  <h5>Booking Party Signature- </h5>
                  <h5>Date- </h5>
                </div>
              `).join('')}
            </div>
            <div>
              <h5>Authorized Signature- </h5>
              <h5>Date - </h5>
            </div>
          </div>
          <script>
            window.onload = () => window.print();
          </script>
        </body>
      </html>
    `;
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
  };
 console.log(cashData,'cashData')
console.log(onOpen,'toggle1',selectId)
  useEffect(() => {
    fetchData();
    fetchData3();
  }, [customerId]);
  const DeleteModal = (id) => {
    setDeleteItemId(id);
    setIsOpen(true);
  };

  const onClose1 = () => {
    setIsOpen(false);
    setDeleteItemId(null);
  };
  

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={4} mb={4} p={4} gap={1}>
      <FormControl>
        <Input
          w='70%'
          type="text"
          placeholder="Search by Phone No or Registration or Name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </FormControl>
      <FormControl  display='flex' flexDirection='row' justifyContent='center' alignItems='center' >
        <FormLabel htmlFor="startDate">Start Date</FormLabel>
        <Input
          id="startDate"
          width='40%'
          name="startDate"
          type="date"
          value={startDate}
          onChange={handleDateChange}
        />
      </FormControl>
      <FormControl  display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
        <FormLabel htmlFor="endDate">End Date</FormLabel>
        <Input
          width='40%'
          id="endDate"
          name="endDate"
          type="date"
          value={endDate}
          onChange={handleDateChange}
        />
      </FormControl>
     
      </Box>
      <Button colorScheme="blue" onClick={exportToExcel} p={2} mb={5}>
       Export to Excel File
      </Button>
      <CashUpdateModal  id={selectId} data={cashData} setCashData={setCashData} isOpen={isOpen} setSelectId={setSelectId} onOpen={onOpen} onClose={onClose}  customerId={customerId} toggle1={toggle1} setToggle1={setToggle1}/> 
      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='yellow'>
          <TableCaption>Cash Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Guest Name</Th>
              <Th>Registration No</Th>
              <Th>Date</Th>
              <Th>Phone No</Th>
              <Th>Room No</Th>
              <Th>Payment Type</Th>
              <Th>Remarks</Th>
              <Th>Cash Paid</Th>
              <Th>Discount</Th>
              <Th>Mode</Th>
              <Th>Transaction ID</Th>
              <Th>Online Paid</Th>
              <Th>Total Paid</Th>
              <Th>Edit</Th>
              <Th>Actions</Th>
              <Th>Delete</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredData.map((item, index) => (

              <Tr key={index}>
                <Td>{item.name}</Td>
                <Td>{item.registration}</Td>
                <Td>{item.date}</Td>
                <Td>{item.phoneNo}</Td>
                <Td>{item.roomNo}</Td>
                <Td>{item.paymentType}</Td>
                <Td>{item.remarks}</Td>
                <Td>{item.cash}</Td>
                <Td>{item.discount}</Td>
                <Td>{item.mode}</Td>
                <Td>{item.transaction}</Td>
                <Td>{item.online}</Td>
                <Td>{parseInt(item.online?item.online : 0)+parseInt(item.cash? item.cash : 0)}</Td>
                <Td><Button colorScheme='green' onClick={()=>edit(item._id)}><FaEdit/></Button></Td>
                <Td>
                  <Button colorScheme="blue" size="sm" onClick={() => handlePrint(item)}>Print</Button>
                </Td>
                <Td>
                  <Button colorScheme="red" size="sm" onClick={() => DeleteModal(item._id)}><FaTrash/></Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <AlertDialog isOpen={isOpen1} leastDestructiveRef={cancelRef} onClose={onClose1}>
      <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose1}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
        
      </AlertDialog>
    </Box>
  );
};

export default CashData;
