import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon

} from '@chakra-ui/react';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const AdvanceModal = ({ isOpen, onClose,checkInDate,checkOutDate,roomNo,customerId,category,ac}) => {
    console.log(checkInDate,checkOutDate,roomNo,customerId,category,ac)
    const [data,setData]=useState([])
    const [formData,setFormData]=useState({
        name: "",
        email:"",
        nationality:"",
        purpose:"",
        registration:"2024-",
        pax:"",
        adult:"",
        child:"",
        date:"",
        arrivedFrom:'',
        nextDestination:"",
        address:"",
        phoneNo:"",
        adhar:"",
        checkIn:'',
        checkOut:'',
        roomNo:'',
        category:'',
        ac: "",
            passport: "",
            issue: "",
            arrival: "",
            placeOfIssue: "",
            stay: "",
            visa: "",
            issueVisa: "",
            expiry: "",
    
    
    })
    const generateRandomRegistration = () => {
      const year = new Date().getFullYear(); // Get current year
      const randomDigits = Math.floor(10000 + Math.random() * 90000); // Generate random 5-digit number
      return `${year}-${randomDigits}`;
    
    };
    const handleDueFocus = () => {
      setFormData({
        ...formData,
        child: formData.pax - formData.adult,
      });
    };
    useEffect(()=>{
        setFormData({
            name: "",
            email:"",
            nationality:"",
            purpose:"",
            registration:"2024-",
            pax:"",
            adult:"",
            child:"",
            date:"",
            arrivedFrom:'',
            nextDestination:"",
            address:"",
            phoneNo:"",
            adhar:"",
            checkIn:checkInDate,
            checkOut:checkOutDate,
            roomNo:roomNo.toString(),
            category:category,
            ac: ac,
                passport: "",
                issue: "",
                arrival: "",
                placeOfIssue: "",
                stay: "",
                visa: "",
                issueVisa: "",
                expiry: "",
        })
    },[roomNo])
    

    const handleEdit = async (e) => {
      const registrationNumber =  generateRandomRegistration();
  
      // Update the form data with the new or existing registration number
      const updatedFormData = {
        ...formData,
        registration: registrationNumber
      };
      e.preventDefault();
      
      try {
          // Make the POST request with formData directly
          const response = await axios.post(`https://api.hotel.tdrsoftware.in/api/advanceRoomBook`, {
              customerId: customerId,
              data: updatedFormData
          });
          
          toast.success(response.data.message);
          console.log(response, 'response');
          
          // Optionally reset the form after a successful update
          setFormData({
              name: "",
              email: '',
              nationality: "",
              purpose: "",
              registration: "",
              pax: "",
              adult: "",
              child: "",
              date: "",
              arrivedFrom: '',
              nextDestination: "",
              address: "",
              phoneNo: "",
              adhar: "",
              checkIn: "",
              checkOut: "",
              roomNo: "",
              category: "",
              ac: "",
              passport: "",
              issue: "",
              arrival: "",
              placeOfIssue: "",
              stay: "",
              visa: "",
              issueVisa: "",
              expiry: "",
          });
  
      } catch (error) {
          toast.error("Error updating room book: " + error.message);
          console.error("Update failed:", error);
      }
  };
  
   
   

   
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    const clear=()=>{
      setFormData({
            name: "",
            email:"",
            nationality:"",
            purpose:"",
            registration:"",
            pax:"",
            adult:"",
            child:"",
            date:"",
            arrivedFrom:'',
            nextDestination:"",
            address:"",
            phoneNo:"",
            adhar:"",
            checkIn:'',
            checkOut:'',
            roomNo:'',
            category:'',
            ac: '',
                passport: "",
                issue: "",
                arrival: "",
                placeOfIssue: "",
                stay: "",
                visa: "",
                issueVisa: "",
                expiry: "",
        })
    }
    const handleSubmit=(e)=>{
       
    }
    // const handleClick=()=>{
    //    setToggle1(!toggle1)
    //   // setBookingData([])
    // }
  return (
    <Modal isOpen={isOpen} onClose={onClose} width='full' size='3xl' scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Booking Information {data.name}</Text>
            <Button colorScheme='red' onClick={onClose} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
         <SimpleGrid columns={2} gap={4} placeItems='left'>
            <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='name' placeholder='Enter name' value={formData.name} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Email</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='email' placeholder='Enter email' value={formData.email} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Nationality</InputLeftAddon>
           <Select width='70%' bg='white' onChange={handleInputChange} name='nationality' placeholder='Enter nationality' value={formData.nationality} >
            <option value={"Indian"}>Indian</option>
            <option value={"Others"}>Others</option>
           </Select>
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Purpose</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='purpose' placeholder='Enter purpose' value={formData.purpose} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Registration</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='registration' placeholder='Enter registration' value={formData.registration} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Pax</InputLeftAddon>
           <Input width='70%' bg='white' onChange={handleInputChange} name='pax' placeholder='Enter pax' value={formData.pax} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Adult</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='adult' placeholder='Enter adult' value={formData.adult} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Child</InputLeftAddon>
           <Input bg='white' onFocus={handleDueFocus} onChange={handleInputChange} name='child' placeholder='Enter child' value={formData.child} />
           </InputGroup>
          <InputGroup>
          <InputLeftAddon>Date
          </InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='date' type='date' placeholder='Enter date' value={formData.date} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Arrived From</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='arrivedFrom' placeholder='Enter Arrived From' value={formData.arrivedFrom} />
           </InputGroup>
          
          <InputGroup>
          <InputLeftAddon>Next Destination</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='nextDestination' placeholder='Enter Next Destination' value={formData.nextDestination} />
          </InputGroup>


          <InputGroup>
          <InputLeftAddon>Address</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='address' placeholder='Enter Address' value={formData.address} />
          </InputGroup>
          <InputGroup>
          <InputLeftAddon>Phone</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='phoneNo' placeholder='Enter Phone Number' value={formData.phoneNo} />
          </InputGroup>


          <InputGroup>
          <InputLeftAddon>Aadhar
          </InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='adhar' placeholder='Enter Adhar Number' value={formData.adhar} />
          </InputGroup> 
        <InputGroup>
        <InputLeftAddon>CheckIn</InputLeftAddon>
        <Input bg='white' type='date' onChange={handleInputChange} name='checkIn' placeholder='Enter CheckIn' value={formData.checkIn} />
        </InputGroup>
          <InputGroup>
          <InputLeftAddon>CheckOut</InputLeftAddon>
          <Input type='date' bg='white' onChange={handleInputChange} name='checkOut' placeholder='Enter CheckOut' value={formData.checkOut} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Room No</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='roomNo' placeholder='Enter Room Number' value={formData.roomNo} isDisabled={true} />
           
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Category</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='category' placeholder='Enter Category' value={formData.category} isDisabled={true} />
           </InputGroup>
           
          <InputGroup>
          <InputLeftAddon>Ac/Non-Ac</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='ac' placeholder='Enter Ac' value={formData.ac} isDisabled={true} />
          </InputGroup>
          <InputGroup>
          <InputLeftAddon>Passport</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='passport' placeholder='Enter Passport' value={formData.passport} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Issue Date</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='issue' type='date' placeholder='Enter Issue Date' value={formData.issue} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Date of Arrival</InputLeftAddon>
           <Input bg='white' type='date' onChange={handleInputChange} name='arrival' placeholder='Enter Date of Arrival' value={formData.arrival} />
           </InputGroup>
          <InputGroup>
          <InputLeftAddon>Place of Issue</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='placeOfIssue' placeholder='Enter Place Of Issue' value={formData.placeOfIssue} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Duration Of Stay</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='stay' placeholder='Enter Duration of Stay' value={formData.stay} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Visa No</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='visa' placeholder='Enter Visa' value={formData.visa} />
           </InputGroup>
           
           <InputGroup>
           <InputLeftAddon>Issue Date of Visa</InputLeftAddon>
           <Input bg='white' type='date' onChange={handleInputChange} name='issueVisa' placeholder='Enter Date of Visa Issue' value={formData.issueVisa} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Expiry Date of Visa</InputLeftAddon>
           <Input bg='white' type='date' onChange={handleInputChange} name='expiry' placeholder='Enter Date of expiry visa' value={formData.expiry} />
           </InputGroup>
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' gap={5} mt={2}>
         <Button colorScheme='blue' onClick={(e)=>handleEdit(e)} >Save</Button>
         <Button colorScheme='red' onClick={clear} >Clear</Button>
         </Box>
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AdvanceModal;
