import logo from "./logo.svg";
import { useState } from "react";
import "./App.css";
import { Form } from "./modules/customer";
import { layout } from "@chakra-ui/react";
import Navbar from "./components/Navbar/Navbar";
//import Sidebar from "./components/Sidebar";
import Layout from "./layout/index";
import { Route,Routes } from "react-router-dom";
import Login from "./modules/auth/Login/Login";
import Signup from "./modules/auth/Signup/Signup";
import  Notes  from "./modules/billing/Note";
import ShowTodo from "./modules/billing/AddFood";
import  TextField  from "./modules/billing/TextField";
import Cookie from 'js-cookie'

import { Toaster } from "react-hot-toast";

function App() {
 // const [showInput, setShowInput] = useState(false);
 const token=Cookie.get('token')
  return (<>{token ?  <div className="App" >
    <Toaster  toastOptions={{
  className: '',
  style: {
    
    padding: '10px',
    color: '#713200',
    width:"300px"
  },
}}   />
    <Layout/>
   
    
   
  </div> :  <div className="App" >
      <Toaster  toastOptions={{
    className: '',
    style: {
      
      padding: '10px',
      color: '#713200',
      width:"300px"
    },
  }}   />
      <Login/>
     
      
     
    </div> }</>
   
  );
}

export default App;
