import { Flex, Box, IconButton, useDisclosure, Stack, Collapse, Text, Button, Link, Center } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { logout } from "../../redux/reducers/authReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { Link as ReactRouterLink } from 'react-router-dom'
import { IoChevronDownOutline } from "react-icons/io5";
import { Link as ChakraLink, LinkProps,Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider, } from '@chakra-ui/react'
  import Cookie from 'js-cookie'
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
const Navbar = () => {
  
  const { isOpen, onToggle } = useDisclosure();
  const navigate=useNavigate();
  const customerId=localStorage.getItem('customerId')
  
   
    console.log(customerId)
    
const dispatch=useDispatch();
const handleLogout=()=>{
  dispatch(logout())
  toast.success('Logout Successfully')
  navigate('/login')
  window.location.reload('/login');
}
const location = useLocation();
const token=Cookie.get('token')

// Function to determine if a menu item is active
const isActive = (path) => {
  return location.pathname === path;
};
const getMenuItemStyle = (path) => {
  return isActive(path) ? { borderBottom: '1px solid #FEA116',color:'white' } : {};
};
  return (
    <Flex
      align="center"
      justify="space-between"
      background="#0F172B"
      padding={{ base: "0.5rem 1rem", md: "1rem 2rem" }}
      borderBottom="1px"
      borderColor="gray.200"
      fontSize='13px'
    >
      <Box display='flex' gap={4} cursor='pointer'>
        {/* <Text fontSize="lg" fontWeight="bold">Your Logo</Text>
        <Text fontSize="lg" fontWeight="bold">Your Logo</Text>
        <Text fontSize="lg" fontWeight="bold">Your Logo</Text> */}
      </Box>

      <Box display={{ base: "block", md: "none" }}>
        <IconButton
          onClick={onToggle}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          variant="ghost"
          aria-label="Toggle navigation"
        />
      </Box>

      <Box display={{ base: "none", lg: "block" }} alignItems='center' >
        {/* Sidebar content */}
        
        <Stack spacing={3} direction="row">
          {customerId ? <>
            <ChakraLink as='b' mr={3} mt={2} p={2}   onClick={()=>navigate('/getMergeBill')}  style={getMenuItemStyle('/getMergeBill')} color='#FEA116' _hover={{color:'white'}}>MergeBill</ChakraLink>
            <ChakraLink as='b' mr={3} mt={2} p={2}   onClick={()=>navigate('/mergeBill')}  style={getMenuItemStyle('/mergeBill')} color='#FEA116' _hover={{color:'white'}}>MergeBill Data</ChakraLink>
            {/* <ChakraLink as='b' mr={3} mt={2} p={2}   onClick={()=>navigate('/customer')}  style={getMenuItemStyle('/customer')} color='#FEA116' _hover={{color:'white'}}>HallBook</ChakraLink> */}
            <ChakraLink as='b' mr={3} mt={2} p={2}   onClick={()=>navigate('/getRoomBook')}  style={getMenuItemStyle('/getRoomBook')} color='#FEA116' _hover={{color:'white'}}>RoomBook</ChakraLink>
        {/* <ChakraLink as='b' mr={3} mt={2} p={2}  onClick={()=>navigate('/roomData')}  style={getMenuItemStyle('/roomData')} color='#FEA116' _hover={{color:'white'}}>Room</ChakraLink> */}
        <Menu size='sm'  >
  <MenuButton mt={2} as={Button} size='sm' bg='#FEA116' _hover={{color:'white'}} rightIcon={<FaChevronDown />}  >
   <Text fontSize='sm'>Room </Text> 
  </MenuButton>
  <MenuList>
    <MenuItem bg='#FEA116' p={2}>
    <ChakraLink as='b'  p={2}  onClick={()=>navigate('/roomData')}   >Room </ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/roomStatus')}   >Room Status</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/advanceRoomBokk')}   >Advance Room Booking</ChakraLink></MenuItem>
    
    
  </MenuList>
</Menu>
        {/* <ChakraLink as='b' mr={3} mt={2} p={2}  onClick={()=>navigate('/roomBillData')}  style={getMenuItemStyle('/roomBillData')} color='#FEA116' _hover={{color:'white'}}>Room Bill</ChakraLink> */}
        <ChakraLink  as='b' mr={3} mt={2} p={2} onClick={()=>navigate('/foodData')}  style={getMenuItemStyle('/foodData')} color='#FEA116' _hover={{color:'white'}}>Food</ChakraLink>
        {/* <ChakraLink as='b' mr={3} mt={2} p={2}  onClick={()=>navigate('/foodBillData')}  style={getMenuItemStyle('/foodBillData')} color='#FEA116' _hover={{color:'white'}}>Food Bill</ChakraLink>
        <ChakraLink as='b' mr={3} mt={2} p={2}  onClick={()=>navigate('/kotBillData')}  style={getMenuItemStyle('/kotBillData')} color='#FEA116' _hover={{color:'white'}}>Kot Bill</ChakraLink> */}
                <Menu  >
  <MenuButton mt={2} size='sm' as={Button} bg='#FEA116' _hover={{color:'white'}} rightIcon={<FaChevronDown />}  >
   <Text fontSize='sm'>Room Bill</Text> 
  </MenuButton>
  <MenuList>
    <MenuItem bg='#FEA116' p={2}>
    <ChakraLink as='b'  p={2}  onClick={()=>navigate('/roomBillData')}   >Room Bill</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/customer')}   >HallRoom Register</ChakraLink></MenuItem>
    <MenuItem bg='#FEA116' ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/estimateData')}   >EstimateBill</ChakraLink></MenuItem>
    
  </MenuList>
</Menu>
        <Menu  >
  <MenuButton mt={2} size='sm' as={Button} bg='#FEA116' _hover={{color:'white'}} rightIcon={<FaChevronDown />}  >
   <Text fontSize='sm'>Food Bill</Text> 
  </MenuButton>
  <MenuList>
    <MenuItem bg='#FEA116' p={2}>
    <ChakraLink as='b'  p={2}  onClick={()=>navigate('/foodBillData')}   >Food Bill</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/kotBillData')}   >Kot Bill</ChakraLink></MenuItem>
    <MenuItem bg='#FEA116' ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/quickBillData')}   >QuickBil</ChakraLink></MenuItem>
    
  </MenuList>
</Menu>
<Menu  >
  <MenuButton mt={2} size='sm' as={Button} bg='#FEA116' _hover={{color:'white'}} rightIcon={<FaChevronDown />}  >
   <Text fontSize='sm'>Inventory </Text> 
  </MenuButton>
  <MenuList>
    <MenuItem bg='#FEA116' p={2}>
    <ChakraLink as='b'  p={2}  onClick={()=>navigate('/supply-register')}   >SupplyRegister</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/item-register')}   >ItemRegister</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/store-register')}   >StoreRegister</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/staff-register')}   >StaffRegister</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/purchase-register')}   >PurchaseRegister</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/store')}   >Store</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/stock-transfer')}   >Stock-Transfer</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/purchase-report')}   >Purchase-Report</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/stock-transfer-report')}   >Stock-Transfer-Report</ChakraLink></MenuItem>
    <MenuItem ><ChakraLink as='b'  p={2}  onClick={()=>navigate('/stock-transfer-summary')}   >Stock-Transfer-Summary</ChakraLink></MenuItem>
    
    
  </MenuList>
</Menu>
  
        <ChakraLink as='b'  mt={2} p={2}  onClick={()=>navigate('/cashData')}  style={getMenuItemStyle('/cashData')} color='#FEA116' _hover={{color:'white'}}>Cash Paid</ChakraLink>
        <Button size='sm' mt={2}   onClick={handleLogout} bg='#FEA116' _hover={{color:'white'}}>Logout</Button>
          </> :<> <Button size='sm' onClick={()=>navigate('/login')} bg='#FEA116' _hover={{color:'white'}}>Login</Button>
          <Button size='sm'  onClick={()=>navigate('/signup')} bg='#FEA116' _hover={{color:'white'}}>Signup</Button></>}
        
        
          
          
          {/* Add more sidebar items as needed */}
        </Stack>
        
      </Box>

      <Collapse in={isOpen} animateOpacity>
        <Box
          display={{ base: isOpen ? "block" : "none", md: "block" }}
          flexBasis={{ base: "100%", ms: "auto" }}
        >
          <Stack
            spacing={4}
            align="center"
            justify={{ base: "center", md: "flex-end" }}
            direction={{ base: "column", md: "row" }}
            pt={{ base: 4, md: 0 }}
          >
            {/* Add more links as needed */}
            <Button>Login</Button>
            <Button>Signup</Button>
          </Stack>
          
        </Box>
      </Collapse>
    </Flex>
  );
};

export default Navbar;
