import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Select,
  Text,
  Flex,
} from "@chakra-ui/react";
import toast from 'react-hot-toast';

const StoreData = () => {
  const [store, setStore] = useState([]);
  const [itemData, setItem] = useState([]);
  const [storeName, setStoreName] = useState('GoDown');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Maximum items per page

  const customerId = localStorage.getItem('customerId');

  const fetchData = async () => {
    try {
      const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/getStoreItems/${customerId}/${storeName}`);
      if (response.data.items.length > 0) {
        setData(response.data.items);
        setFilteredData(response.data.items);
        toast.success(response.data.message);
      } else {
        setData([]);
        setFilteredData([]);
        toast.error('No items found in this store');
      }
    } catch (error) {
      toast.error('Failed to fetch data');
    }
  };

  const fetchData3 = async () => {
    try {
      const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/item/${customerId}`);
      setItem(response.data.item);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchData2 = async () => {
    try {
      const response = await axios.get(`https://api.hotel.tdrsoftware.in/api/store/${customerId}`);
      setStore(response.data);
    } catch (error) {
      toast.error('Failed to fetch stores');
    }
  };

  const handleChange = (e) => {
    const selectedStoreName = e.target.value;
    setStoreName(selectedStoreName);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    filterData(event.target.value);
  };

  const filterData = (term) => {
    let filtered = data;
    if (term) {
      filtered = filtered.filter(item =>
        item.itemName.toLowerCase().includes(term.toLowerCase())
      );
    }
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchData();
  }, [storeName]);

  useEffect(() => {
    fetchData2();
    fetchData3();
  }, [customerId]);

  return (
    <Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={4} mb={4} p={4} gap={10}>
        <Input
          w='30%'
          type="text"
          placeholder="Search Item name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <Select w='30%' name='storeName' value={storeName} onChange={handleChange} >
          {store.map((store, index) => (
            <option key={index} value={store.name}>{store.name}</option>
          ))}
        </Select>
      </Box>

      <TableContainer width='full'>
        <Table size='sm' variant='striped' colorScheme='yellow'>
          <TableCaption>Store Data</TableCaption>
          <Thead>
            <Tr>
              <Th>Item Name</Th>
              {/* <Th>Purchase Rate</Th> */}
              <Th>Quantity</Th>
              <Th>Stock</Th>
              <Th>Unit</Th>
              {/* <Th>Sgst</Th>
              <Th>Cgst</Th>
              <Th>Amount</Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {currentItems && currentItems.length > 0 ? (
              currentItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{item.itemName}</Td>
                  {/* <Td>{item.purchaseRate}</Td> */}
                  <Td>{item.quantity}</Td>
                  <Td>
                    {Math.floor(item.quantity) + " " + itemData.find(i => item.itemName === i.name)?.purchaseUnit + " " +
                    Math.floor  (
                        (item.quantity - Math.floor(item.quantity)) *
                        parseInt(itemData.find(i => item.itemName === i.name)?.equivalentNo?.match(/=\s*(\d+)/)?.[1] || 0)
                      ) + " " +
                      itemData.find(i => item.itemName === i.name)?.usageUnit}
                  </Td>
                  <Td>{item.unit}</Td>
                  {/* <Td>{item.sgst}</Td>
                  <Td>{item.cgst}</Td>
                  <Td>{item.amount}</Td> */}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={8} textAlign="center">
                  No items found in this store
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <Flex justifyContent="center" alignItems="center" mt={4}>
        <Button onClick={handlePreviousPage} disabled={currentPage === 1} mr={2}>
          Previous
        </Button>
        <Text>Page {currentPage} of {totalPages}</Text>
        <Button onClick={handleNextPage} disabled={currentPage === totalPages} ml={2}>
          Next
        </Button>
      </Flex>
    </Box>
  );
};

export default StoreData;
